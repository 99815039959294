@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";


.rdw-editor-main {
    border: 1px solid #f6f6f6;
    height: 239px;
    padding-left: 15px;
}

.text-red {
    color: red
}
